<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="hot-sell">
      <div class="hot-sell-in">
        <div class="hot-sell-title">
          <div class="hot-sell-img-in">
            <img src="@/../public/icon/mall/hotSell/sell_01.png" alt="">
          </div>
        </div>
        <b-container>
          <b-row>
            <b-col class="type-nav">
              <ul>
                <li v-for="(item, index) in hotSellType" :key="index" @click="changeType(item)">
                  <div class="pic">
                    <img :src="item.IMG_PATH" :alt="item.LABEL_NAME">
                  </div>
                  <span>{{item.LABEL_NAME}}</span>
                </li>
              </ul>
            </b-col>
          </b-row>
          <b-row class="b-row">
            <b-col class="b-col" sm="6" :md="index < 2 ? 6 : 4" :lg="index < 3 ? 4 : 3" :xl="index < 3 ? 4 : 3" v-for="(item, index) in hotSellGoods" :key="index">
              <div class="item-wrap" @click="toProductDetail(item)">
                <div class="rank" :class="{'first': index == 0 ? true : false, 'second': index == 1 ? true : false, 'third': index == 2 ? true : false}">
                  <span>{{index + 1 | filterFun}}</span>
                </div>
                <div class="pic">
                  <img :src="item.file_path" :alt="item.goods_name">
                </div>
                <div class="detail">
                  <div class="name">{{item.style_name}} {{item.goods_name}}</div>
                  <div class="price">￥{{item.sale_price}}</div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import Right from "@/components/Right.vue";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      Right,
      footerNav
    },
    data (){
      return {
        token: null,
        hotSellType: [],
        hotSellGoods: [],
        styleTyle: ""
      }
    },
    created() {
      this.init();
    },
    filters: {
      filterFun(value){
        if (value.toString().length === 1) {
          return '0' + value
        }else{
          return value
        }
      }
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getHotSellTypeLabel();
        this.getHotSellGoods();
      },
      // 获取商城热销排行类型
      getHotSellTypeLabel(){
        this.$api.hotSellTypeLabel({
          style_type: 3
        }).then(res => {
          if(res.status == 100){
            res.data.push({
              IMG_PATH: require("@/../public/icon/mall/hotSell/hot-all.png"),
              LABEL_ID: "",
              LABEL_NAME: "全部"
            })
            this.hotSellType = res.data;
          }
        }).catch(err => console.error(err));
      },
      // 切换热销产品类型
      changeType(obj){
        this.styleTyle = obj.LABEL_ID;
        this.getHotSellGoods();
      },
      // 获取热销商品
      getHotSellGoods(){
        this.$api.hotSellGoods({
          sort_type: 1,
          currentPage: 1,
          style_type: this.styleTyle,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.hotSellGoods = res.data.goods;
          }
        })
      },
      // 跳转到商品详情
      toProductDetail(obj){
        dump.link({
          type: 3,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/mall/hotSell/hotSell";
</style>
